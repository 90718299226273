.plotsphoto {
  max-width: 37rem;
  position: relative;
  width: 100%;
  height: 25rem;
  margin-left: 2rem;
  border-radius: 30px;
}
.plotsphoto img {
  width: 37rem;
  border-radius: 30px;
  display: block;
  height: 25rem;
  z-index: 2;
}
.overlayplots {
  position: absolute;
  width: 37rem;
  height: 25rem;
  /* top: 0;
  left: 0; */
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;

  justify-content: flex-end;
  flex-direction: column;

  border-radius: 30px;
  opacity: 0;
  top: 0;
  left: 0;
}
.overlayplots:hover {
  opacity: 1;
}
.content {
  display: flex;
  flex-direction: column;
  height: 5rem;
  background: #ececec;
  border-radius: 0px 0px 30px 30px;
}
@media screen and (max-width: 570px) {
  .plotsphoto {
    position: relative;
    max-width: 20rem;
    width: 100%;
    height: 75%;
    margin-left: 2rem;
    border-radius: 30px;
  }
  .plotsphoto img {
    min-width: 20rem;
    width: 100%;
    border-radius: 30px;
    display: block;
    height: 100%;
    z-index: 2;
  }
}
