.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 50rem;
  background-color: white;
  margin-top: 5rem;
}
.formcontainer {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f2f2f2;
  border-radius: 34px;
  width: 28rem;
  height: 35rem;
}
.formcontainer input[type="text"],
.formcontainer input[type="text"]::-webkit-input-placeholder {
  background: #f9c460;
  border-radius: 12px;
  width: 24rem;
  height: 3rem;
  border: none;
  margin-top: 2rem;
  font-family: "Lato";
  font-style: normal;
  font-weight: 200;
  font-size: 1rem;
  line-height: 29px;
  text-align: left;

  color: #ffffff;
}

.formcontainer button {
  background: #f9c460;
  border-radius: 12px;
  width: 8rem;
  height: 2.5rem;
  margin-top: 2rem;
  border: none;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 29px;
  text-align: center;

  color: #000000;
}
.imagecontainer {
  width: 50%;
  height: 100%;
}
.imagecontainer img {
  width: 100%;
  height: 80%;
}
@media screen and (max-width: 900px) {
  .imagecontainer {
    display: none;
  }
  .formcontainer {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f2f2f2;
    border-radius: 34px;
    width: 18rem;
    height: 18rem;
  }
  .formcontainer input[type="text"],
  .formcontainer input[type="text"]::-webkit-input-placeholder {
    width: 14rem;
    height: 3rem;
    margin-top: 0.5rem;
  }
  .formcontainer button {
    width: 5rem;
    height: 1.25rem;
    margin-top: 0.5rem;
    font-size: 13px;
  }
} ;
