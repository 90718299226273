.mainvideo {
  width: 60%;
  height: 29rem;
}
.mainvideo video {
  width: 100%;
  /* object-fit: fill; */
  height: 100%;
}
.image {
  width: 30rem;
  height: 20rem;
}
.gotplot {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  /* width: 100%; */
  height: 50%;
  z-index: 3;
  margin-top: 3rem;
  padding-top: 1rem;
  gap: 20px;
  margin-inline: 30px;
}
.got {
  width: 20rem;
  /* margin-left: 3rem; */
  margin-right: 3rem;
}
.gotplotsbutton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
}
.gotplotsbutton button {
  background-color: #01518a;
  padding: 12px;
  border-radius: 8px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  margin-top: 3.5rem;
  color: #ffffff;
  border: none;
  cursor: pointer;
  margin-bottom: 1rem;
}
.gotplotsbutton button:hover {
  color: #01518a;
  background-color: #ffffff;
  border: 1px solid #01518a;
  transition: all 0.2s ease-in;
}
.frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.frame div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: -3rem;
  width: 5rem;
  height: 5rem;
  background-color: white;
  border-radius: 50%;
  border: 2px solid #f9c460;
}
.frame img {
  width: 4rem;
  height: 4.3rem;
  margin-top: 0.1rem;
}
.faq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.iot {
  margin-top: 3rem;
  width: 100%;
  height: 25rem;
  margin-left: 0;
  display: flex;
  flex-direction: row;
}
.iot div {
  margin: 0;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.iot div img {
  width: 94%;
  height: 100%;
  border-radius: 25px;
}
.i {
  border: 1px solid #f9c460;
  border-radius: 25px;
}
.smart {
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  margin-top: 7rem;

  color: #000000;
  margin-bottom: 2rem;
}
/* .howitworks {
  background: rgba(249, 196, 96, 0.09);
} */
.sturdy {
  width: 100%;
  height: 35rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.sturdyimg {
  width: 55%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.sturdyimg img {
  width: 92%;
  height: 80%;
}
.iotcontent {
  margin-top: 2rem;
  text-align: center;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  text-align: center;

  color: #000000;
}
.sturdycontent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 40%;
  height: 100%;
}
.mainproduct {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-color: #ced3cd; */
  /* background-color: #f8fdf6; */
  background-color: #c5cac4;
  background-image: linear-gradient(to right, #fffffb, #d7d8d7 30%, #fffffb);
  padding: 30px 10px;
}
@media screen and (max-width: 910px) {
  .gotplotsbutton {
    display: flex;
    flex-direction: column;
  }
  .gotplotsbutton button {
    width: 15rem;
    height: 4rem;
    margin-left: 0;
    margin-right: 0;
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 31px;
    text-align: center;
    margin-top: 4rem;

    color: #333333;
  }
  .iot {
    margin-top: 3rem;
    width: 100%;
    height: 50rem;
    margin-left: 0;
    display: flex;
    flex-direction: column;
  }
  .iot div {
    margin: 0;
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .iot img {
    width: 80%;
    height: 100%;
  }
  .sturdy {
    display: flex;
    flex-direction: column;
    height: 50rem;
  }
  .sturdyimg {
    width: 100%;
    height: 50%;
  }
  .sturdycontent {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
  }
}
@media screen and (max-width: 1039px) {
  .image {
    width: 20rem;
    height: 10rem;
  }
}
@media screen and (max-width: 280px) {
  .image {
    width: 10rem;
    height: 5rem;
  }
}
@media screen and (max-width: 900px) {
  .gotplot {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50%;
    z-index: 3;
    margin-top: 3rem;
  }
  .got {
    width: 15rem;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 450px) {
  .mainvideo {
    width: 100%;
    height: 50%;
  }
  .mainvideo video {
    width: 100%;
    height: 100%;
  }
  .mainproduct {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .frame {
    width: 3rem;
    height: 3rem;
  }
  .gotplotsbutton button {
    width: 15rem;
    height: 4rem;
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    margin-top: 4rem;

    color: #333333;
  }
}
