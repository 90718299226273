.sturdypara {
  width: 100%;
  height: 6rem;
  background: #ffffff;
  border: 2px solid #f9c460;
  border-radius: 15px;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.sturdypara span {
  background: #f9c460;
  border: 3px solid #ffffff;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 3rem;
  position: relative;
  left: -1.5rem;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  /* identical to box height */

  text-align: center;

  color: #000000;
  top: 1rem;
  height: 3rem;
}
.sturdypara p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 36px;
  text-align: center;

  color: #000000;
}

@media screen and (max-width: 910px) {
  .sturdypara {
    width: 60%;
    height: 4rem;
    background: #ffffff;
    border: 2px solid #f9c460;
    border-radius: 15px;
    margin-top: 2rem;
  }
  .sturdypara span {
    width: 2.5rem;
    position: relative;
    left: -1.6rem;
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 2px;
    text-align: center;
    color: #000000;
    top: 0.6rem;
    min-height: 2.5rem;
  }
  .sturdypara p {
    font-family: "Lato";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    color: #000000;
  }
}
@media screen and (max-width: 450px) {
  .sturdypara {
    width: 50%;
    height: 4rem;
    margin-top: 1rem;
  }

  .sturdypara span {
    background: #f9c460;
    border: solid #ffffff;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 2rem;
    left: -0.8rem;
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 0.5rem;
    line-height: 1px;
    /* identical to box height */

    text-align: center;

    color: #000000;
    top: 0.31rem;
    height: 2rem;
  }
  .sturdypara p {
    font-family: "Lato";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;

    color: #000000;
  }
}
