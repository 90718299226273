.main {
  background-image: url("../../public/assets/Rectangle\ 1555.png");
  height: 35rem;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 0;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.overlay {
  width: 100%;
  height: 20rem;
  display: flex;
  background: rgba(255, 167, 92, 0.81);
  opacity: 0.8;
  position: absolute;
  z-index: 2;
}
.overlaywritten {
  display: flex;
  flex-direction: row;
  z-index: 3;
  margin-top: 1rem;
}
.mainleft {
  display: flex;
  flex-direction: row;
  width: 20%;
}
.mainleft div {
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 7px;
  margin-left: 1rem;
}

.maincenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  font-family: "Verdana";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 61px;

  color: #ffffff;
}

.mainright {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 20%;
}
.mainright div {
  width: 3rem;
  height: 3rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  margin-left: 2.5rem;
}
.mainright img {
  position: absolute;
}
.mainright p {
  font-size: 8px;
  position: absolute;
  color: #ffa75c;
  margin-top: 2.4rem;
}
.plotscollection {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 2rem;
  flex-direction: row;
  overflow-y: auto;
}
.plotscollection::-webkit-scrollbar {
  display: none;
}
.title {
  text-align: left;
  margin: 1.5em 2em .8em;
  font-family: "Verdana";
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 46px;

  color: #000000;
}
.sub {
  text-align: left;
  margin-top: 0;
  margin-left: 3rem;

  font-family: "Verdana";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;

  color: #6d6d6d;
}
.recently {
  width: 100%;
  height: 33rem;
  display: flex;
  margin-top: 3rem;
  flex-direction: row;
  overflow-y: auto;
  overflow-y: hidden;
}
.recently::-webkit-scrollbar {
  display: none;
}
.explorecity {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.blogs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blogbutton {
  /* border: 1px solid; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1em;
}
.blogbutton button {
  /* margin-left: 2rem; */
  /* margin-right: 2rem; */
  border-radius: 15px;
  width: 14rem;
  height: 4rem;
  font-family: "Verdana";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 39px;
  border: none;
  /* border: 2px solid red; */
  /* identical to box height */

  text-align: center;
}
.active {
  background: #ffa75c;
  color: #ffffff;
}
.blogbutt {
  width: 15rem;
  height: 4rem;
  border-radius: 15px;
  margin-top: 3rem;
  background: #ffa75c;
  color: #ffffff;
  border: none;
  font-family: "Verdana";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
}
.customer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
}
.faq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1150px) {
  .blogbutton {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 949px) {
  .customer {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }
}
@media screen and (max-width: 570px) {
  .plotscollection {
    width: 100%;
    height: 80%;
    display: flex;
    margin-top: 2rem;
    flex-direction: row;
    overflow-y: hidden;
  }
  .blogbutton button {
    width: 12rem;
    height: 3rem;
    /* margin-top: 2rem; */
  }
  .maincenter {
    font-size: 25px;
    font-weight: 550;
    line-height: 40px;
  }
  .main {
    background-image: url("../../public/assets/Rectangle\ 1555.png");
    height: 20rem;
    width: 100%;
  }
  .overlay {
    width: 100%;
    height: 13rem;
    display: flex;
    background: rgba(255, 167, 92, 0.81);
    opacity: 0.8;
    position: absolute;
    z-index: 2;
  }
  .overlaywritten {
    display: flex;
    flex-direction: row;
    z-index: 3;
    margin-top: 0.5rem;
  }
  .mainleft div {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
  }
  .mainleft img {
    width: 1rem;
    height: 1rem;
  }
  .mainright div {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1rem;
  }
  .mainright img {
    width: 0.8rem;
    height: 0.8rem;
  }
}
