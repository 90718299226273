.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-self: center;
  margin-left: 3rem;
  margin-top: 3rem;
}
.sessionnav {
  display: flex;
  flex-direction: row;
  background-color: #ffa75c;
  align-items: center;
  height: 5rem;
}
.hamburger {
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 7px;
  margin-left: 1rem;
}
.icon {
  width: 3rem;
  height: 3rem;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 7px;
  margin-left: 2.5rem;
}
.search {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.filters {
  width: 100%;
  background: #f5f5f5;
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.allfilter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 20rem;
}
.allfilter:hover {
  border-bottom: 5px solid #ffa75c;
}

@media screen and (max-width: 480px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 3rem;
  }
}
