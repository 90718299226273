.mediacard {
  width: 23rem;
  height: 27rem;
  border: 1px solid #c8c8c8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 33px;
  margin-left: 3rem;
  margin-top: 5rem;
}
.mediacard img {
  width: 20rem;
  height: 22rem;
  position: relative;
  border-radius: 33px;
  top: -3rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.mediacardcontent {
  display: flex;
  flex-direction: column;
  top: -3rem;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  height: 6.5rem;
}
.mediacardcontent p {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.head {
  font-family: "Verdana";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  text-align: justify;
  line-height: 34px;
  /* identical to box height */

  color: #000000;
}
.place {
  font-family: "Verdana";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  color: #6d6d6d;
}
.rate {
  font-family: "Verdana";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  color: #000000;
}
@media screen and (max-width: 900px) {
  .mediacard {
    position: relative;
    width: 17rem;
    height: 16rem;
    border: 1px solid #c8c8c8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 33px;
    margin-top: 5rem;
    left: -3rem;
  }
  .mediacard img {
    width: 16rem;
    height: 15rem;
    position: relative;
    border-radius: 33px;
    top: -3rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
}
